import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  storage = localStorage;

  constructor() { }

  save(value: any) {
    this.storage.setItem("Session", IsJsonObject(value));
  }

  get() {
    return IsJsonString(this.storage.getItem("Session"));
  }

  delete() {
    this.storage.removeItem("Session");
  }

  clear() {
    this.storage.clear();
  }

}

function IsJsonObject(dataValue: any) {
  "use strict";
  try {
    return JSON.stringify(dataValue);
  } catch (e) {
    return dataValue;
  }
}

function IsJsonString(dataValue: any){
  "use strict";
  try {
    return JSON.parse(dataValue);
  } catch (e) {
    return dataValue;
  }
}
