import { Injectable } from '@angular/core';
import {AlertController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alert: HTMLIonAlertElement;

  constructor(private alertController: AlertController) { }

  async presentDeleteAlert(item: string, specified: string, confirmed: () =>void) {
    this.alert = await this.alertController.create({
      header: `Eliminar ${item}`,
      message: `¿Está seguro de eliminar ${specified}? <strong>Esta acción no se puede deshacer.</strong>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Okay',
          handler: confirmed
        }
      ]
    });

    await this.alert.present();
  }

  async presentErrorAlert(message: string, confirmed: () =>void) {
    this.alert = await this.alertController.create({
      header: 'Ha ocurrido un error',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: confirmed
        }, {
          text: 'Aceptar',
          handler: confirmed
        }
      ]
    });

    await this.alert.present();
  }
}
