import { Injectable } from '@angular/core';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {SessionService} from "../services/session.service";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor{

  constructor(private session:SessionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let bearerToken: string = "Bearer ";
    if (this.session.get() !== null) {
      bearerToken += this.session.get().token;
    }
    console.log(bearerToken);
    let headers: any = {
      'Authorization': bearerToken,
      'Access-Control-Allow-Origin': '*',
    }

    let request = req.clone({
      url: req.url.indexOf("http") != -1 ? req.url : (environment.baseUrl + environment.gateway + req.url),
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      catchError(this.handleError)
    );

  }

  handleError(error: HttpErrorResponse) {
    console.warn(error);
    return throwError(error.message);
  }

}

export interface HTTPResponse {
  success: boolean,
  code: number,
  message: string,
  data: any
}
