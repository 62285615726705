import { Injectable } from '@angular/core';
import {LoadingController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading: HTMLIonLoadingElement;
  constructor(private loadingController: LoadingController) {

  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: 'Estamos trabajando en ello...',
    });
    await this.loading.present();
  }

  async dismiss(){
    this.loading.dismiss();
  }

}
