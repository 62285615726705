import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HTTPResponse} from "../interceptors/http-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  endpoint = '/login';

  constructor(private http: HttpClient) { }

  logIn(map: {user: string, password: string}): Observable<HTTPResponse> {
    if (!environment.mockup) {
      return this.http.post(this.endpoint, map) as Observable<HTTPResponse>;
    } else {
      return new Observable<HTTPResponse>((subscriber) => {
        setTimeout(()=>{
          subscriber.next( {
            success: true,
            code: 200,
            message: "",
            data: {
              token: '234234534434321ewdfgnh4yt342ewqdsfgrt4t34r2ewqdfrg4t534r2ewqdsvfb',
              staff: {
                staff_id: 1,
                organization_id: 1,
                position_id: 4,
                firstname: "Humberto",
                surname: "López",
                rfc: "",
                email: null,
                telephone: "7751624201",
                phone: "7751624201",
                address: "Av Ignacio López Rayon #66",
                position: "Software Developer",
                country_id: 1,
                country: "México"
              },
            }
          });
          subscriber.complete();
        }, 3000);
      });
    }
  }


}
