import { Injectable } from '@angular/core';
import {ToastController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toast: HTMLIonToastElement;

  constructor( private toastController:ToastController ) { }

  async presentErrorFormToast() {
    this.toast = await this.toastController.create({
      message: `<ion-icon name="close-outline"></ion-icon>  Por favor, revisa los campos del formulario`,
      duration: 3000,
      cssClass: "error-toast",
      position: "top",
      color: 'dark',
    });
    await this.toast.present();
  }

  async presentErrorToast(message: string) {
    this.toast = await this.toastController.create({
      message: `<ion-icon name="close-outline"></ion-icon>   ${message}`,
      duration: 3000,
      cssClass: "error-toast",
      position: "top",
      color: 'dark',
    });
    await this.toast.present();
  }

  async presentDeleteToast(message: string) {
    this.toast = await this.toastController.create({
      message: `<ion-icon name="cloud-done-sharp"></ion-icon>   ${message}`,
      duration: 3000,
      cssClass: "success-toast",
      position: "top",
      color: 'dark',
    });
    await this.toast.present();
  }

  async presentUpdateToast(message: string) {
    this.toast = await this.toastController.create({
      message: `<ion-icon name="cloud-done-sharp"></ion-icon>   ${message}`,
      duration: 3000,
      cssClass: "success-toast",
      position: "top",
      color: 'success',
    });
    await this.toast.present();
  }

  async presentCreateToast(message: string) {
    this.toast = await this.toastController.create({
      message: `<ion-icon name="cloud-done-sharp"></ion-icon>   ${message}`,
      duration: 3000,
      cssClass: "success-toast",
      position: "top",
      color: 'primary',
    });
    await this.toast.present();
  }
}
