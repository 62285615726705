import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from "../../providers/login.service";
import {HTTPResponse} from "../../interceptors/http-interceptor.service";
import {SessionService} from "../../services/session.service";
import {LoadingService} from "../../services/loading.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../services/toast.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginProvider: LoginService,
    private session: SessionService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private alertService: AlertService,
    ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get password (){ return this.form.controls['password'] as FormControl }

  logIn(){
    console.log(this.form.value);
    if (this.form.valid){
      this.loadingService.presentLoading();
      this.loginProvider.logIn(this.form.value)
        .subscribe(
          (response: HTTPResponse) => {
            if (response.success && response.code === 200){
              this.loadingService.dismiss();
              this.session.save(response.data);
              this.router.navigate(['dashboard/module/'])
            } else {
              this.loadingService.dismiss();
              this.alertService.presentErrorAlert(response.message, ()=>{});
            }
          }
        )
    } else this.toastService.presentErrorFormToast();
  }

}
